import { useHistory, useLocation } from 'react-router-dom'
import { useState } from 'react'
import CommonButton from '../CommonButton/CommonButton'
import Logo from '../../../public/rework/logo.svg'
import { useSelector } from 'react-redux'
import MenuIcon from './MenuIcon'
import useMediaQuery from '../../../lib/hooks/useMediaQuery'
import UserNavbar from '../../Dashboard/UserNavbar/UserNavbar'
import { userData, useUserStatus } from '../../../redux/auth/selectors'
import * as S from './Navbar.styles'

interface ILink {
  link: string
  name: string
}

const landingLinks: ILink[] = [
  { link: '/research-and-development', name: 'Projekt B+R' },
  { link: '/tdm', name: 'TDM' },
  { link: '/about-us', name: 'Kim jesteśmy' },
  { link: '/laboratory', name: 'Laboratorium' },
  { link: '/advantages', name: 'Zalety usługi' },
]

export default function Navbar() {
  const isDesktop = useMediaQuery('(min-width: 1024px')
  const { isAdmin } = useSelector(useUserStatus)
  const history = useHistory()
  const { pathname } = useLocation()
  const isDashboard = pathname.includes('dashboard')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { email, name, surname } = useSelector(userData)

  const dashboardLinks: ILink[] = [
    { link: '/', name: 'Strona główna' },
    { link: '/dashboard/submissions', name: 'Zlecenia' },
    { link: '/dashboard/locations', name: 'Placówki' },
    { link: '/dashboard/settings', name: 'Ustawienia' },
  ]
  if (isAdmin) {
    dashboardLinks.push({ link: '/dashboard/users', name: 'Użytkownicy' })
  }
  const linksToDisplay = isDashboard ? dashboardLinks : landingLinks

  const loginAndCloseModal = () => {
    setIsMenuOpen(!isMenuOpen)
    history.push('/dashboard')
  }

  return (
    <S.Wrapper>
      <S.ContactWrapper>
        <S.ContactItem href={'tel:+48 505 543 170'}>
          <S.MobileIcon />
          <S.Label>+48 505 543 170</S.Label>
        </S.ContactItem>
        <S.ContactItem href={'mailto:biuro@monitlab.pl'}>
          <S.MailIcon />
          <S.Label>biuro@monitlab.pl</S.Label>
        </S.ContactItem>
        <S.ContactAddress>
          <S.MapPin />
          <S.Label>Pl. Kilińskiego 2, 35-005 Rzeszów</S.Label>
        </S.ContactAddress>
      </S.ContactWrapper>
      <S.Main>
        <S.LinkLogo to={isDashboard ? '/dashboard' : '/'}>
          <S.Image src={Logo} alt={'Monitlab logo'} />
        </S.LinkLogo>
        {isDesktop && (
          <S.Nav>
            {linksToDisplay.map(({ link, name }: ILink, index: number) => (
              <S.NavItem
                to={link}
                key={`navLink_${index}`}
                params={{ isSelected: link === '/' ? pathname === '/' : pathname.includes(link) }}
              >
                {name}
              </S.NavItem>
            ))}
          </S.Nav>
        )}
        {isDesktop ? (
          isDashboard ? (
            <UserNavbar name={`${name ? name : ''} ${surname ? surname : ''}`} surname={email} />
          ) : (
            <CommonButton label={'Strefa lekarza'} onClick={() => history.push('/dashboard')} />
          )
        ) : (
          <S.BurgerButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <MenuIcon isOpen={isMenuOpen} />
          </S.BurgerButton>
        )}
      </S.Main>
      {!isDesktop && (
        <S.NavMenu isOpen={isMenuOpen}>
          {(isDashboard ? dashboardLinks : landingLinks).map(({ link, name }: ILink, index: number) => (
            <S.NavItem
              to={link}
              key={`navLink_${index}`}
              onClick={() => setIsMenuOpen(false)}
              params={{ isSelected: link === '/' ? pathname === '/' : pathname.includes(link) }}
            >
              {name}
            </S.NavItem>
          ))}
          <CommonButton label={'Strefa lekarza'} onClick={loginAndCloseModal} />
        </S.NavMenu>
      )}
    </S.Wrapper>
  )
}
